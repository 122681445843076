@import "../theme/core/variables";

$taskSpacing: 1.5rem;

.PlaybookPage {
    @include breakpoint(lg) {
        padding: 2rem;
    }

    .PlaybookCard {
        background-color: $colorWhite;
        border-radius: $borderRadius;
        // padding: 1rem;
        box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15) !important;
        border: 1px solid $colorGray3;
    }

    .task {
        position: relative;

        .task-line {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 0.75rem;
            height: 4rem;

            &.checked {
                .task-title {
                    text-decoration: line-through;
                }
            }

            input[type="checkbox"] {
                flex: 0 0 auto;
                appearance: none;
                position: relative;
                width: 1.5rem;
                height: 1.5rem;
                margin-right: $taskSpacing;

                &:after {
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: calc(1.5rem + 2px);
                    height: calc(1.5rem + 2px);
                    border: 1px solid #e8e8e8;
                    border-radius: 3px;
                    background-color: #fff;
                    position: absolute;
                    content: ' ';
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                &:checked:after {
                    content: ' ';
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M19.055 6.867a.54.54 0 0 1 .07.211.54.54 0 0 1-.07.211L10.3 18.54c-.14.14-.246.211-.317.211-.117 0-.234-.059-.351-.176l-4.957-4.781-.106-.105a.54.54 0 0 1-.07-.211c0-.024.023-.082.07-.176l.07-.07a69.366 69.366 0 0 1 1.548-1.618c.14-.14.234-.21.28-.21.095 0 .212.07.352.21l2.813 2.742 7.031-9.035c.047-.047.117-.07.211-.07.07 0 .152.023.246.07l1.934 1.547Z' fill='%234184F2' fill-rule='nonzero'/%3E%3C/svg%3E");
                    background-color: rgba($colorBlue, 0.1);
                }
            }

            .task-clickable {
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex: 1 1 auto;
                height: 4rem;

                &:hover {
                    .view-task {
                        color: $colorGray7;
                    }
                }

                .task-title {
                    // flex: 1 1 auto;
                    display: -webkit-box;
                    max-width: 100%;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-weight: 500;
                }

                .view-task {
                    flex: 0 0 auto;
                    margin-left: $taskSpacing;
                    text-transform: uppercase;
                    font-size: 0.75rem;
                    font-weight: $fontWeightSemibold;
                    color: $colorGray4;
                }
            }
        }

        &.selected {
            background-color: $colorGray1;

            @include breakpoint(md) {

                // Only hide on medium... on small we'll show "Hide"
                .view-task {
                    visibility: hidden;
                }

                // Right Arrow
                &::after {
                    position: absolute;
                    right: -1.5rem;
                    top: 0;
                    content: ' ';
                    width: 0;
                    height: 0;
                    border-top: 2rem solid transparent;
                    border-bottom: 2rem solid transparent;
                    border-left: 1.5rem solid $colorGray1;
                    -webkit-filter: drop-shadow(1px 0px 0 $colorGray3);
                    filter: drop-shadow(1px 0px 0 $colorGray3);
                }
            }
        }

        .task-body {
            background-color: $colorWhite;
            padding: 1rem;
        }

        &:not(:last-child) {
            border-bottom: 1px solid $colorGray3;
        }

        // Ensure we preserve border-radius even when selected
        &:first-child {
            border-top-left-radius: $borderRadius;
        }

        // Ensure we preserve border-radius even when selected
        &:last-child {
            border-bottom-left-radius: $borderRadius
        }

        @include breakpoint(sm, down) {

            // Ensure we preserve border-radius even when selected - unnecessary on md up, because of arrow
            &:first-child {
                border-top-right-radius: $borderRadius;
            }

            // Ensure we preserve border-radius even when selected - unnecessary on md up, because of arrow
            &:last-child {
                border-bottom-right-radius: $borderRadius
            }
        }
    }

    img {
        max-width: 100%;
        height: auto;
    }

    a {
        color: $colorLink;
        text-decoration: none;

        &:active,
        &:hover,
        &:focus {
            color: $colorLinkHover;
        }
    }
}