// MISC
// $borderRadius: 0.25rem;
$borderRadius: 0;

// MAIN COLORS
// $colorBlue: #4184F2;
// $colorBlue: #54BAE7;
// $colorBlue: #01B1FF;
$colorBlue: #11B2FA;
$colorRed: #fd4d3e;
$colorGreen: #5AA18A;
// $colorRed: #EB4335;
// $colorGreen: #36A853;
// $colorOrange: #FCC833;
// $colorYellow: #FCC833;
$colorOrange: #ff920d;
$colorYellow: #f8e71c;

$colorPrimary: $colorBlue;
$colorSecondary: $colorYellow;
$colorLink: $colorBlue;
$colorLinkHover: darken($colorLink, 20%);

// GRAYS:
$colorWhite: #fff;
$colorGray1: #f9f9f9;
$colorGray2: #f2f2f2;
$colorGray3: #eee;
$colorGray4: #ccc;
$colorGray5: #bbb;
$colorGray6: #999;
$colorGray7: #666;
$colorGray8: #444;
$colorGray9: #222;
$colorBlack: #000;

// FONTS
$fontFamilyPrimary: montserrat, sans-serif;
$fontFamilySecondary: montserrat, sans-serif;

// WEIGHTS
$fontWeightLight: 400;
$fontWeightNormal: 400;
$fontWeightMedium: 400;
$fontWeightSemibold: 900;
$fontWeightBold: 900;

// // FONTS
// $fontFamilyPrimary: "Nunito Sans", sans-serif;
// $fontFamilySecondary: "Nunito Sans", sans-serif;

// // WEIGHTS
// $fontWeightLight: 400;
// $fontWeightNormal: 400;
// $fontWeightMedium: 400;
// $fontWeightSemibold: 700;
// $fontWeightBold: 900;

// SIZES - Sizes are defined using REMs for accessibility, but named using pixels (based on base of 16) for simplicity's sake
$fontSize48: 3rem; // 48px
$fontSize40: 2.5rem; // 40px
$fontSize32: 2rem; // 32px
$fontSize28: 1.75rem;
$fontSize24: 1.5rem;
$fontSize20: 1.25rem;
$fontSize18: 1.125rem;
$fontSize16: 1rem;
$fontSize14: 0.875rem;
$fontSize12: 0.75rem;
$fontSize10: 0.625rem;
$fontSize8: 0.5rem;

// Breakpoints
$breakpoints: (
    "sm": 576px,
    "md": 768px,
    "lg": 992px,
    "xl": 1200px,
    ) !default;

@mixin breakpoint($breakpoint, $direction: up) {
    @if map-has-key($breakpoints, $breakpoint) {
        @if $direction==down {

            // Prints a media query based on the value
            @media (max-width: map-get($breakpoints, $breakpoint)) {
                @content;
            }
        }

        @else {

            // Prints a media query based on the value
            @media (min-width: map-get($breakpoints, $breakpoint)) {
                @content;
            }
        }
    }

    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        +"Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}