.hidden {
    display: none !important;
}

@each $breakpoint in map-keys($breakpoints) {
    .hidden-#{$breakpoint}-up {
        @include breakpoint($breakpoint) {
            display: none !important;
        }
    }

    .hidden-#{$breakpoint}-down {
        @include breakpoint($breakpoint, down) {
            display: none !important;
        }
    }
}