.font-weight-light {
    font-weight: $fontWeightLight;
    font-family: $fontFamilyPrimary;
}

.font-weight-medium {
    font-weight: $fontWeightMedium;
    font-family: $fontFamilySecondary;
}

.font-weight-semibold {
    font-weight: $fontWeightSemibold;
    font-family: $fontFamilyPrimary;
}

.font-weight-bold {
    font-weight: $fontWeightBold;
    font-family: $fontFamilySecondary;
}

.font-48 {
    font-size: $fontSize48;
}

.font-40 {
    font-size: $fontSize40;
}

h1,
.font-32 {
    font-size: $fontSize32;
}

h2,
.font-24 {
    font-size: $fontSize24;
}

h3,
.font-20 {
    font-size: $fontSize20;
}

.font-18 {
    font-size: $fontSize18;
}

.font-16 {
    font-size: $fontSize16;
}

.font-14 {
    font-size: $fontSize14;
}

.font-12 {
    font-size: $fontSize12;
}

.font-10 {
    font-size: $fontSize10;
}

.font-8 {
    font-size: $fontSize8;
}

// COLORS

/* Basics */
.font-normal {
    color: $colorBlack;
}

body {
    //h1, h2, h3, h4, h5, h6, a, textarea, input, select
    // &: extend(.font-normal); // LESS
    @extend .font-normal;
}

.font-contrast {
    color: $colorWhite;
}

/* Main Colors */
.font-blue {
    color: $colorBlue  !important;
}

.font-green {
    color: $colorGreen  !important;
}

.font-red {
    color: $colorRed  !important;
}

.font-yellow {
    color: $colorYellow  !important;
}

.font-orange {
    color: $colorOrange  !important;
}

/* Grays */
.font-white,
.font-contrast {
    color: $colorWhite  !important;
}

.font-gray-1 {
    color: $colorGray1  !important;
}

.font-gray-2 {
    color: $colorGray2  !important;
}

.font-gray-3 {
    color: $colorGray3  !important;
}

.font-gray-4 {
    color: $colorGray4  !important;
}

.font-gray-5 {
    color: $colorGray5  !important;
}

.font-gray-6 {
    color: $colorGray6  !important;
}

.font-gray-7 {
    color: $colorGray7  !important;
}

// STYLES
.text-justify {
    text-align: justify !important;
}

.text-nowrap {
    white-space: nowrap !important;
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-lowercase {
    text-transform: lowercase !important;
}

.text-uppercase {
    text-transform: uppercase !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-underline,
.text-decoration-underline {
    text-decoration: underline;
}

.font-style-normal {
    font-style: normal;
}

.font-style-italic {
    font-style: italic;
}

.text-decoration-none {
    text-decoration: none;
}

.text-truncate-ellipsis {
    text-overflow: ellipsis;
}

.text-wrap-none {
    white-space: nowrap;
}

.text-word-wrap {
    word-wrap: break-word;
}

.text-smoothing {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.strike-through {
    text-decoration: line-through;
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important;
    }

    .text-xl-right {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

// LINE HEIGHTS
.line-height-100 {
    line-height: 1;
}

.line-height-110 {
    line-height: 1.1;
}

.line-height-125 {
    line-height: 1.25;
}

.line-height-150 {
    line-height: 1.5;
}

.line-height-200 {
    line-height: 2;
}

.line-height-250 {
    line-height: 2.5;
}

// IMPLEMENT IT ALL
.font-face-normal,
body,
ol,
ul,
li {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $fontFamilyPrimary;
    font-weight: $fontWeightLight;
    line-height: 1.25;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $fontFamilySecondary;
    line-height: 1;
    font-weight: $fontWeightBold;
    // text-transform: uppercase;
    // text-shadow: -0.125em -0.125em $colorBlue;
    // text-shadow: -0.1em -0.1em $colorBlue;
}

.header-underline {
    display: block;
    width: max-content;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 0.25rem;
    border-bottom: 2px solid $colorGray6;
    font-weight: $fontWeightSemibold;
    border-bottom: 1px solid $colorBlack;
}

p {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $fontFamilyPrimary;
    font-weight: $fontWeightLight;
    line-height: 1.5;
    margin-bottom: 1.5rem;
}

// Misc
.subtitle {
    font-size: $fontSize18;
    color: $colorGray6;
    text-transform: uppercase;
    font-weight: $fontWeightSemibold;
}

.list-bullet>li {
    list-style: inside;
    list-style-type: disc;
}

.white-space-pre {
    white-space: pre;
}

.white-space-pre-line {
    white-space: pre-line;
}

.white-space-pre-wrap {
    white-space: pre-wrap;
}

strong {
    font-weight: $fontWeightSemibold;
}

///
.mkt-copy {
    font-size: $fontSize18;
    max-width: 800px;
    margin: 0 auto;
}

a {
    color: unset;
    text-decoration: none;
}

// a:not(.btn) {
//     color: $colorLink;
//     text-decoration: none;

//     &:active,
//     &:hover,
//     &:focus {
//         color: $colorLinkHover;
//     }
// }

a.link {
    color: $colorLink;
    text-decoration: none;

    &:active,
    &:hover,
    &:focus {
        color: $colorLinkHover;
    }
}

a.alternate {
    color: $colorRed;
    text-decoration: underline dashed;

    &:active,
    &:hover,
    &:focus {
        color: $colorBlue;
    }
}