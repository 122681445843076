.img-fluid,
.img-responsive,
.img-full,
figure.img-fluid > img {
    width: 100%;
    height: auto;
}

.img-natural,
.img-natural > img {
    width: unset;
}
