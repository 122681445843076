@mixin sm-max-width {
    @include breakpoint(sm) {
        max-width: 512px;
        margin-left: auto;
        margin-right: auto;
    }
}

@mixin md-max-width {
    @include breakpoint(md) {
        max-width: 688px;
        margin-left: auto;
        margin-right: auto;
    }
}

@mixin lg-max-width {
    @include breakpoint(lg) {
        max-width: 928px;
        margin-left: auto;
        margin-right: auto;
    }
}

.lg-max-width {
    @include lg-max-width;
}

@mixin xl-max-width {
    @include breakpoint(xl) {
        max-width: 1120px;
        margin-left: auto;
        margin-right: auto;
    }
}

.xl-max-width {
    @include xl-max-width;
}

@mixin full-width {
    margin-left: calc(-100vw / 2 + 100% / 2);
    margin-right: calc(-100vw / 2 + 100% / 2);
    max-width: 100vw;
}

.align-full {
    @include full-width;
}

.gap-sm {
    gap: 1.5rem;
}

.gap-md {
    gap: 3rem;
}

.gap-lg {
    gap: 6rem;
}

/* GRID!!!!!!!!! */
/* Containers are now for content w/o rows */
/* Containers are now for content w/o rows */
.row,
.container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
}

.row-fluid,
.container-fluid {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.row,
.row-fluid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 1rem;
    /* Make row-gap 1rem so it always fits, even at small breakpoints */
}

@media (min-width: 576px) {

    .row,
    .row-fluid {
        grid-gap: 1rem 2rem;
        /* At larger breakpoints, row-gap can be larger  */
    }

    .row,
    .container {
        /* max-width: 540px; */
        /* max-width: 510px; */
        max-width: 512px;
    }
}

@media (min-width: 768px) {

    .row,
    .container {
        /* max-width: 720px; */
        /* max-width: 690px; */
        max-width: 688px;
    }
}

@media (min-width: 992px) {

    .row,
    .container {
        /* max-width: 960px; */
        /* max-width: 930px; */
        max-width: 928px;
    }
}

@media (min-width: 1200px) {

    .row,
    .container {
        /* max-width: 1140px; */
        /* max-width: 1110px; */
        max-width: 1120px;
    }
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
    /* width: 100%; */
    grid-column-start: auto;
    grid-column-end: span 12;
}

.col-12 {
    grid-column-start: auto;
    grid-column-end: span 12;
}

.col-11 {
    grid-column-start: auto;
    grid-column-end: span 11;
}

.col-10 {
    grid-column-start: auto;
    grid-column-end: span 10;
}

.col-9 {
    grid-column-start: auto;
    grid-column-end: span 9;
}

.col-8 {
    grid-column-start: auto;
    grid-column-end: span 8;
}

.col-7 {
    grid-column-start: auto;
    grid-column-end: span 7;
}

.col-6 {
    grid-column-start: auto;
    grid-column-end: span 6;
}

.col-5 {
    grid-column-start: auto;
    grid-column-end: span 5;
}

.col-4 {
    grid-column-start: auto;
    grid-column-end: span 4;
}

.col-3 {
    grid-column-start: auto;
    grid-column-end: span 3;
}

.col-2 {
    grid-column-start: auto;
    grid-column-end: span 2;
}

.col-1 {
    grid-column-start: auto;
    grid-column-end: span 1;
}

@media (min-width: 576px) {
    .col-sm-12 {
        grid-column-start: auto;
        grid-column-end: span 12;
    }

    .col-sm-11 {
        grid-column-start: auto;
        grid-column-end: span 11;
    }

    .col-sm-10 {
        grid-column-start: auto;
        grid-column-end: span 10;
    }

    .col-sm-9 {
        grid-column-start: auto;
        grid-column-end: span 9;
    }

    .col-sm-8 {
        grid-column-start: auto;
        grid-column-end: span 8;
    }

    .col-sm-7 {
        grid-column-start: auto;
        grid-column-end: span 7;
    }

    .col-sm-6 {
        grid-column-start: auto;
        grid-column-end: span 6;
    }

    .col-sm-5 {
        grid-column-start: auto;
        grid-column-end: span 5;
    }

    .col-sm-4 {
        grid-column-start: auto;
        grid-column-end: span 4;
    }

    .col-sm-3 {
        grid-column-start: auto;
        grid-column-end: span 3;
    }

    .col-sm-2 {
        grid-column-start: auto;
        grid-column-end: span 2;
    }

    .col-sm-1 {
        grid-column-start: auto;
        grid-column-end: span 1;
    }
}

@media (min-width: 768px) {
    .col-md-12 {
        grid-column-start: auto;
        grid-column-end: span 12;
    }

    .col-md-11 {
        grid-column-start: auto;
        grid-column-end: span 11;
    }

    .col-md-10 {
        grid-column-start: auto;
        grid-column-end: span 10;
    }

    .col-md-9 {
        grid-column-start: auto;
        grid-column-end: span 9;
    }

    .col-md-8 {
        grid-column-start: auto;
        grid-column-end: span 8;
    }

    .col-md-7 {
        grid-column-start: auto;
        grid-column-end: span 7;
    }

    .col-md-6 {
        grid-column-start: auto;
        grid-column-end: span 6;
    }

    .col-md-5 {
        grid-column-start: auto;
        grid-column-end: span 5;
    }

    .col-md-4 {
        grid-column-start: auto;
        grid-column-end: span 4;
    }

    .col-md-3 {
        grid-column-start: auto;
        grid-column-end: span 3;
    }

    .col-md-2 {
        grid-column-start: auto;
        grid-column-end: span 2;
    }

    .col-md-1 {
        grid-column-start: auto;
        grid-column-end: span 1;
    }
}

@media (min-width: 992px) {
    .col-lg-12 {
        grid-column-start: auto;
        grid-column-end: span 12;
    }

    .col-lg-11 {
        grid-column-start: auto;
        grid-column-end: span 11;
    }

    .col-lg-10 {
        grid-column-start: auto;
        grid-column-end: span 10;
    }

    .col-lg-9 {
        grid-column-start: auto;
        grid-column-end: span 9;
    }

    .col-lg-8 {
        grid-column-start: auto;
        grid-column-end: span 8;
    }

    .col-lg-7 {
        grid-column-start: auto;
        grid-column-end: span 7;
    }

    .col-lg-6 {
        grid-column-start: auto;
        grid-column-end: span 6;
    }

    .col-lg-5 {
        grid-column-start: auto;
        grid-column-end: span 5;
    }

    .col-lg-4 {
        grid-column-start: auto;
        grid-column-end: span 4;
    }

    .col-lg-3 {
        grid-column-start: auto;
        grid-column-end: span 3;
    }

    .col-lg-2 {
        grid-column-start: auto;
        grid-column-end: span 2;
    }

    .col-lg-1 {
        grid-column-start: auto;
        grid-column-end: span 1;
    }
}

@media (min-width: 1200px) {
    .col-xl-12 {
        grid-column-start: auto;
        grid-column-end: span 12;
    }

    .col-xl-11 {
        grid-column-start: auto;
        grid-column-end: span 11;
    }

    .col-xl-10 {
        grid-column-start: auto;
        grid-column-end: span 10;
    }

    .col-xl-9 {
        grid-column-start: auto;
        grid-column-end: span 9;
    }

    .col-xl-8 {
        grid-column-start: auto;
        grid-column-end: span 8;
    }

    .col-xl-7 {
        grid-column-start: auto;
        grid-column-end: span 7;
    }

    .col-xl-6 {
        grid-column-start: auto;
        grid-column-end: span 6;
    }

    .col-xl-5 {
        grid-column-start: auto;
        grid-column-end: span 5;
    }

    .col-xl-4 {
        grid-column-start: auto;
        grid-column-end: span 4;
    }

    .col-xl-3 {
        grid-column-start: auto;
        grid-column-end: span 3;
    }

    .col-xl-2 {
        grid-column-start: auto;
        grid-column-end: span 2;
    }

    .col-xl-1 {
        grid-column-start: auto;
        grid-column-end: span 1;
    }
}

/* START Classes - Similar to Offsets */
.start-1 {
    grid-column-start: 1;
}

.start-2 {
    grid-column-start: 2;
}

.start-3 {
    grid-column-start: 3;
}

.start-4 {
    grid-column-start: 4;
}

.start-5 {
    grid-column-start: 5;
}

.start-6 {
    grid-column-start: 6;
}

.start-7 {
    grid-column-start: 7;
}

.start-8 {
    grid-column-start: 8;
}

.start-9 {
    grid-column-start: 9;
}

.start-10 {
    grid-column-start: 10;
}

.start-11 {
    grid-column-start: 11;
}

/* Starts - SM */
@media (min-width: 576px) {
    .start-sm-1 {
        grid-column-start: 1;
    }

    .start-sm-2 {
        grid-column-start: 2;
    }

    .start-sm-3 {
        grid-column-start: 3;
    }

    .start-sm-4 {
        grid-column-start: 4;
    }

    .start-sm-5 {
        grid-column-start: 5;
    }

    .start-sm-6 {
        grid-column-start: 6;
    }

    .start-sm-7 {
        grid-column-start: 7;
    }

    .start-sm-8 {
        grid-column-start: 8;
    }

    .start-sm-9 {
        grid-column-start: 9;
    }

    .start-sm-10 {
        grid-column-start: 10;
    }

    .start-sm-11 {
        grid-column-start: 11;
    }
}

/* Starts - MD */
@media (min-width: 768px) {
    .start-md-1 {
        grid-column-start: 1;
    }

    .start-md-2 {
        grid-column-start: 2;
    }

    .start-md-3 {
        grid-column-start: 3;
    }

    .start-md-4 {
        grid-column-start: 4;
    }

    .start-md-5 {
        grid-column-start: 5;
    }

    .start-md-6 {
        grid-column-start: 6;
    }

    .start-md-7 {
        grid-column-start: 7;
    }

    .start-md-8 {
        grid-column-start: 8;
    }

    .start-md-9 {
        grid-column-start: 9;
    }

    .start-md-10 {
        grid-column-start: 10;
    }

    .start-md-11 {
        grid-column-start: 11;
    }
}

/* Starts - LG */
@media (min-width: 992px) {
    .start-lg-1 {
        grid-column-start: 1;
    }

    .start-lg-2 {
        grid-column-start: 2;
    }

    .start-lg-3 {
        grid-column-start: 3;
    }

    .start-lg-4 {
        grid-column-start: 4;
    }

    .start-lg-5 {
        grid-column-start: 5;
    }

    .start-lg-6 {
        grid-column-start: 6;
    }

    .start-lg-7 {
        grid-column-start: 7;
    }

    .start-lg-8 {
        grid-column-start: 8;
    }

    .start-lg-9 {
        grid-column-start: 9;
    }

    .start-lg-10 {
        grid-column-start: 10;
    }

    .start-lg-11 {
        grid-column-start: 11;
    }
}

/* Starts - XL */
@media (min-width: 1200px) {
    .start-xl-1 {
        grid-column-start: 1;
    }

    .start-xl-2 {
        grid-column-start: 2;
    }

    .start-xl-3 {
        grid-column-start: 3;
    }

    .start-xl-4 {
        grid-column-start: 4;
    }

    .start-xl-5 {
        grid-column-start: 5;
    }

    .start-xl-6 {
        grid-column-start: 6;
    }

    .start-xl-7 {
        grid-column-start: 7;
    }

    .start-xl-8 {
        grid-column-start: 8;
    }

    .start-xl-9 {
        grid-column-start: 9;
    }

    .start-xl-10 {
        grid-column-start: 10;
    }

    .start-xl-11 {
        grid-column-start: 11;
    }
}