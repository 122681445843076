@import "../theme/core/variables";

.LandingPage {
    .row-fluid {
        padding: 0;
    }

    .col {
        padding: 2rem 1rem;

        @include breakpoint(md) {
            padding: 3rem;
        }
    }

    .bg-col {
        background-color: $colorGray8;
        background-image: url('./lp-bg.jpg');
        background-position: center;
        background-size: cover;

        @include breakpoint(md) {
            min-height: 100vh;
        }
    }

    .pre-header {
        color: $colorGray6;
        text-transform: uppercase;
        padding-bottom: 0.25rem;
        border-bottom: 1px dashed $colorGray6;
        display: inline-block;
        font-size: 0.75rem;
    }

    img {
        width: 261px;
        max-width: 80vw;
        height: auto;
    }

    h1 {
        font-size: 2.5rem;
        text-transform: uppercase;
    }

    .highlight {
        color: $colorBlue;
        font-size: 1.25rem;
    }

    ul {
        li:not(:last-child) {
            margin-bottom: 1rem;
        }
    }

    .LandingSignIn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;

        .card {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 20rem;
            max-width: 100%;
        }

        input:not(:last-of-type) {
            margin-bottom: 1rem;
        }
    }
}