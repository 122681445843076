@import "../theme/core/variables";
// @import "../theme/core/breakpoints";

.SignIn {
    padding: 2rem;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $colorGray3;
    // background: #D9ECEA;
    text-align: center;

    // h2 {
    //     color: $colorBlack !important;
    // }

    img {
        width: 200px;
        height: auto;
        margin-bottom: 2rem;
    }

    .card {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 20rem;
        max-width: calc(100% - 4rem);
    }

    .change-mode-link {
        display: inline-block;
        color: $colorLink;
        border-bottom: 1px dashed $colorLink;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
            color: $colorLinkHover;
            border-bottom: 1px dashed $colorLinkHover;
        }
    }

    input:not(:last-of-type) {
        margin-bottom: 1rem;
    }

    .version {
        position: absolute;
        right: 1rem;
        bottom: 1rem;
        font-size: 0.625rem;
    }
}