@import "../theme/core/variables";
// @import "../theme/core/breakpoints";

.Layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    nav {
        width: 100%;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
        border-bottom: 1px solid $colorGray3;
        background: $colorWhite;

        @include breakpoint(md) {
            padding-left: 2rem;
            padding-right: 2rem;
        }

        a {
            line-height: 1;
        }

        img {
            max-height: 3rem;
            width: auto;

            @include breakpoint(sm, 'down') {
                // max-width: 75%;
                max-width: calc(100vw - 5rem - 80px); // 100vw - padding and space between button - button width)
                height: auto;
            }
        }
    }

    main {
        background: $colorWhite;
        color: $colorBlack;
        min-height: calc(100vh - 10rem);
        // padding: 2rem;
        padding: 1rem 0;

        @include breakpoint(md) {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        // @include breakpoint(sm, 'down') {
        //     padding: 1rem;
        // }
    }

    footer {
        background-color: $colorBlack;
        color: $colorWhite;
        width: 100%;
        height: 5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1rem;
    }
}