@import "../theme/core/variables";

.TipTap {
    padding: 1rem;
    border: 1px solid $colorGray3;
    border-radius: $borderRadius;

    .TipTapMenu {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding-bottom: 1rem;
        border-bottom: 1px solid $colorGray3;
        margin-bottom: 1rem;
        // justify-content: center;

        // input,
        // select 

        button {
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: inherit;
            font-family: inherit;
            color: #000;
            // margin: .1rem;
            border: none;
            opacity: 0.5;

            padding: 0.25rem;
            // border: 1px solid black;
            border-radius: .3rem;
            // padding: .1rem .4rem;
            background: white;
            accent-color: black;

            &[disabled] {
                opacity: .3;
            }

            &.is-active {
                opacity: 1;
                // background: black;
                // color: #fff
            }
        }

        .MenuSpacer {
            // width: 10px;
            height: 1rem;
            width: 1px;
            border-right: 1px solid $colorGray3;
            margin: 0 0.5rem;
        }
    }

    /* Basic editor styles */
    .ProseMirror {
        &:focus {
            outline: none;
        }

        >*+* {
            margin-top: 0.75em;
        }

        ul,
        ol {
            padding: 0 1rem;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            line-height: 1.1;
        }

        code {
            background-color: rgba(#616161, 0.1);
            color: #616161;
        }

        pre {
            background: #0D0D0D;
            color: #FFF;
            font-family: 'JetBrainsMono', monospace;
            padding: 0.75rem 1rem;
            border-radius: 0.5rem;

            code {
                color: inherit;
                padding: 0;
                background: none;
                font-size: 0.8rem;
            }
        }

        img {
            max-width: 100%;
            height: auto;
        }

        blockquote {
            padding-left: 1rem;
            border-left: 2px solid rgba(#0D0D0D, 0.1);
        }

        hr {
            border: none;
            border-top: 2px solid rgba(#0D0D0D, 0.1);
            margin: 2rem 0;
        }

        p:last-child {
            margin-bottom: 0;
        }

        a {
            color: $colorLink;
            text-decoration: none;

            &:active,
            &:hover,
            &:focus {
                color: $colorLinkHover;
            }
        }
    }



    // *,
    // *:before,
    // *:after {
    //     box-sizing: border-box
    // }

    // html {
    //     font-family: Inter, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji";
    //     line-height: 1.5;
    //     -webkit-font-smoothing: antialiased;
    //     -moz-osx-font-smoothing: grayscale
    // }

    // body {
    //     margin: 1rem;
    //     min-height: 10rem
    // }

    // ::-webkit-scrollbar {
    //     width: 14px;
    //     height: 14px
    // }

    // ::-webkit-scrollbar-track {
    //     border: 4px solid transparent;
    //     background-clip: padding-box;
    //     border-radius: 8px;
    //     background-color: transparent
    // }

    // ::-webkit-scrollbar-thumb {
    //     border: 4px solid rgba(0, 0, 0, 0);
    //     background-clip: padding-box;
    //     border-radius: 8px;
    //     background-color: #0000
    // }

    // :hover::-webkit-scrollbar-thumb {
    //     background-color: #0000001a
    // }

    // ::-webkit-scrollbar-thumb:hover {
    //     background-color: #00000026
    // }

    // ::-webkit-scrollbar-button {
    //     display: none;
    //     width: 0;
    //     height: 0
    // }

    // ::-webkit-scrollbar-corner {
    //     background-color: transparent
    // }



    // .ProseMirror:focus {
    //     outline: none
    // }
}