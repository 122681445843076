@import "../theme/core/variables";

.MainPage {
    .playbook-card {
        position: relative;
        padding: 1.5rem;
        overflow: hidden;

        &::before {
            position: absolute;
            left: 0;
            top: 0.5rem;
            transform-origin: bottom left;
            transform: rotate(-45deg);
            content: ' ';
            width: 0;
            height: 0;
            border-left: 1.5rem solid transparent;
            border-right: 1.5rem solid transparent;
            border-bottom: 1.5rem solid $colorGray3;

        }

        &.playbook-complete {
            &::before {
                border-bottom: 1.5rem solid $colorGreen;
            }
        }

        &.playbook-current {
            &::before {
                border-bottom: 1.5rem solid $colorBlue;
            }
        }

        .playbook-title {
            font-size: 1.5rem;
            font-weight: $fontWeightSemibold;
            margin-bottom: 0.5rem;
        }

        .playbook-goal {
            font-weight: $fontWeightSemibold;
            text-transform: uppercase;
            color: $colorGray6;
            letter-spacing: 1px;
            margin-bottom: 0.5rem;
        }
    }

}