/* Background Basics */
.bg-inherit {
    background-color: inherit;
}
.bg-none {
    background: none;
}
.bg-transparent {
    background-color: transparent;
}

/* Grays */
.bg-white {
    background-color: $colorWhite;
}
.bg-gray-1 {
    background-color: $colorGray1;
}
.bg-gray-2 {
    background-color: $colorGray2;
}
.bg-gray-3 {
    background-color: $colorGray3;
}
.bg-gray-4 {
    background-color: $colorGray4;
}
.bg-gray-5 {
    background-color: $colorGray5;
}
.bg-gray-6 {
    background-color: $colorGray6;
}
.bg-gray-7 {
    background-color: $colorGray7;
}
.bg-gray-8 {
    background-color: $colorGray8;
}
.bg-gray-9 {
    background-color: $colorGray9;
}

/* Colors */
.bg-blue {
    background-color: $colorBlue;
}
.bg-green {
    background-color: $colorGreen;
}
.bg-orange {
    background-color: $colorOrange;
}
.bg-red {
    background-color: $colorRed;
}
